import { Brands } from '../../assets/brands/brands';

export const EL_PRICE_PARAM_ID = '-32002';

export const phone = {
  regex: /^(\+)?[\d\-)(]+$/,
  minLength: 5,
  maxLength: 25,
};

export const address = {
  postalCode: {
    regex: /^[a-zA-Z\d\- ]+$/,
    maxLength: 20,
  },
  cityNameMaxLength: 100,
  regionNameMaxLength: 100,
  pattern: /^[\p{L}\p{Nd}'"\-–.,#&/() ]+$/u,
};

export const emailAddress = {
  maxLength: 255,
  antiXssRegex: /^[^%<>^$'`*|{}="]+$/,
};

export const yourTechnicianPattern = {
  name: {
    pattern: /^[\p{L}\p{Nd}'\-. ]+$/u,
    maxLength: 50,
  },
};

export const defaultValueLowerLimit = -32760;

export const timeZoneOffsetRegex = /\(([^)]+)\)/;
export const externalLinkRegex = /^https?:\/\//;
export const userNameRegex = /^[\p{L}\p{Nd}'\-. ]+$/u;
export const dateTimeFormatString = 'YYYY-MM-DDTHH:mm:ssZ';
export const dateFormatForVaction = 'YYYY/MM/DD';
export const passwordMaxLength = 128;
export const defaultMaxLength = 255;

export const FaqQuestionType = {
  Regular: 'regular',
  Custom: 'custom',
};

export const BRANDS_WITH_ECOM = [Brands.NIBE];

export const BRANDS_WITH_NOEM_FIRMWARE = [Brands.AIT, Brands.NOVELAN, Brands.CTA];

export const ELIGIBLE_BRANDS_FOR_HIDING_PREMIUM = [
  Brands.CETETHERM,
  Brands.CTC,
  Brands.IEC,
  Brands.AIT,
  Brands.NOVELAN,
  Brands.CTA,
  Brands.ENERTECH,
];
export const ELIGIBLE_BRANDS_FOR_HIDING_HISTORY = [Brands.AIT, Brands.NOVELAN, Brands.CTA];

export const noRegion = { name: 'None', code: 'NONE' };

export const freeBrand = Brands.CONTURA;

export const ELIGIBLE_BRANDS_FOR_MAVENOID = [Brands.NIBE, Brands.NIBEF];
export const ELIGIBLE_NIBEF_FIRMWARES_FOR_MAVENOID = [
  'nibe-e1',
  'nibe-e2',
  'nibe-e3',
  'nibe-e4',
  'nibe-e5',
  'nibe-e6',
  'nibe-e7',
  'nibe-e8',
  'nibe-e9',
  'nibe-e10',
  'nibe-e11',
  'nibe-e12',
  'nibe-e13',
];

export const tabNames = {
  PROFILE: 'profile',
  APPEARANCE: 'appearance',
  NOTIFICATION_SETTINGS: 'notification-settings',
  PERMISSIONS: 'permission',
  PASSWORD: 'password',
  DELETE: 'delete',
};

export const BRANDS_WITH_HIDDEN_STORE = [Brands.JASPI, Brands.JAMA];
