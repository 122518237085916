import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import SignInLayoutRoute from '../layout/SignInLayout';
import SignOutLayoutRoute from '../layout/SignOutLayout';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import NewPassword from './NewPassword';
import Register from './Register';

import NotFoundPage from './NotFound';

import AboutPage from './About';
import AddDevice from './AddDevice';
import Alarms from './Alarms';
import ConfirmEmail from './ConfirmEmail';
import ConfirmEmailUpdate from './ConfirmEmailUpdate';
import ContactPage from './Contact';
import CookiesPage from './Cookies';
import DeleteConfirmation from './DeleteConfirmation';
import Devices from './Devices';
import DeviceScheduling from './DeviceScheduling';
import DeviceSettings from './DeviceSettings';
import FaqPage from './Faq';
import History from './history';
import Home from './Home';
import Invitation from './Invitation';
import PrivacyPolicyLegalPage from './LegalPages/PrivacyPolicyLegalPage';
import TermsOfServiceLegalPage from './LegalPages/TermsOfServiceLegalPage';
import OrderCancel from './OrderCancel';
import PrivacyPolicyPage from './PrivacyPolicy';
import ProductRegistration from './ProductRegistration';
import ProductRegistrationInfo from './ProductRegistrationInfo';
import ProfilePage from './ProfilePage';
import RedeemVoucher from './RedeemVoucher';
import SendConfirmEmailLink from './SendConfirmEmailLink';
import SmartGuidePage from './SmartGuide';
import Store from './Store';
import SystemProfilePage from './SystemProfile';
import TermsOfServicePage from './TermsOfService';
import UpdateDeviceFirmware from './UpdateDeviceFirmware';
import Warranty from './Warranty';
import WorksWith from './WorksWith';

import {
  isSystemEligibleForHidingHistory,
  isSystemEligibleForHidingPremium,
  isSystemHidingStoreMenu,
  selectDevicesFirmwareId,
} from '../../utils/system';

const STORE_ROUTES = ['/store', '/redeem-voucher'];

const routesDefinition = {
  anonymousRoutes: [
    { path: '/login', component: <SignOutLayoutRoute path="/login" component={Login} /> },
    { path: '/sign-up', component: <SignOutLayoutRoute path="/sign-up" component={Login} /> },
    { path: '/forgot-password', component: <SignOutLayoutRoute path="/forgot-password" component={ForgotPassword} /> },
    { path: '/password-reset', component: <SignOutLayoutRoute path="/password-reset" component={NewPassword} /> },
    { path: '/register', component: <SignOutLayoutRoute path="/register" component={Register} /> },
    {
      path: '/confirmation-link',
      component: <SignOutLayoutRoute path="/confirmation-link" component={SendConfirmEmailLink} />,
    },
    {
      path: '/delete/confirmation',
      component: <SignOutLayoutRoute path="/delete/confirmation" component={DeleteConfirmation} />,
    },
    {
      path: '/confirm-email/:token',
      component: <SignOutLayoutRoute path="/confirm-email/:token" component={ConfirmEmail} />,
    },
    {
      path: '/confirm-email-update',
      component: <SignOutLayoutRoute path="/confirm-email-update" component={ConfirmEmailUpdate} />,
    },
    {
      path: '/legal/privacy-policy/:locale',
      component: <SignOutLayoutRoute path="/legal/privacy-policy/:locale?" component={PrivacyPolicyLegalPage} />,
    },
    {
      path: '/legal/terms-of-service/:locale',
      component: <SignOutLayoutRoute path="/legal/terms-of-service/:locale?" component={TermsOfServiceLegalPage} />,
    },
    //TODO: Remove redundant route once Google Home no longer references these pages.
    {
      path: '/legal/privacy-policy-step-1/:locale',
      component: <Route path="/legal/privacy-policy-step-1/:locale" component={PrivacyPolicyLegalPage} />,
    },
    {
      path: '/legal/privacy-policy-step-2/:locale',
      component: <Route path="/legal/privacy-policy-step-2/:locale" component={PrivacyPolicyLegalPage} />,
    },
    {
      path: '/legal/terms-of-service-step-1/:locale',
      component: <Route path="/legal/terms-of-service-step-1/:locale" component={TermsOfServiceLegalPage} />,
    },
    {
      path: '/legal/terms-of-service-step-2/:locale',
      component: <Route path="/legal/terms-of-service-step-2/:locale" component={TermsOfServiceLegalPage} />,
    },
    {
      path: '/legal/terms-of-service-step-3/:locale',
      component: <Route path="/legal/terms-of-service-step-3/:locale" component={TermsOfServiceLegalPage} />,
    },
    {
      path: '/legal/about/:locale',
      component: <SignOutLayoutRoute path="/legal/about/:locale" component={AboutPage} />,
    },
    {
      path: '/legal/works-with/:locale',
      component: <SignOutLayoutRoute path="/legal/works-with/:locale" component={WorksWith} />,
    },
    { path: '/warranty', component: <Route path="/warranty" component={Warranty} /> },
  ],
  restrictedRoutes: {
    systemDependentRoutes: [
      { path: '/history', component: <SignInLayoutRoute path="/history" component={History} /> },
      {
        path: '/system-profile',
        component: <SignInLayoutRoute path="/system-profile" component={SystemProfilePage} />,
      },
      { path: '/device-settings', component: <SignInLayoutRoute path="/device-settings" component={DeviceSettings} /> },
      { path: '/devices', component: <SignInLayoutRoute path="/devices" component={Devices} /> },
      { path: '/redeem-voucher', component: <SignInLayoutRoute path="/redeem-voucher" component={RedeemVoucher} /> },
      { path: '/smartguide', component: <SignInLayoutRoute path="/smartguide" component={SmartGuidePage} /> },
      { path: '/store/cancel', component: <SignInLayoutRoute path="/store/cancel" component={OrderCancel} /> },
      { path: '/store', component: <SignInLayoutRoute path="/store" component={Store} /> },
      { path: '/cancellation', component: <SignInLayoutRoute path="/cancellation" component={Store} /> },
      {
        path: '/device-scheduling',
        component: <SignInLayoutRoute path="/device-scheduling" component={DeviceScheduling} />,
      },
    ],
    systemIndependentRoutes: [
      { path: '/warranty', component: <SignInLayoutRoute path="/warranty" component={Warranty} /> },
      //TODO: Remove redundant route once Google Home no longer references these pages
      {
        path: '/legal/privacy-policy-step-1/:locale',
        component: <Route path="/legal/privacy-policy-step-1/:locale" component={PrivacyPolicyLegalPage} />,
      },
      {
        path: '/legal/privacy-policy-step-2/:locale',
        component: <Route path="/legal/privacy-policy-step-2/:locale" component={PrivacyPolicyLegalPage} />,
      },
      {
        path: '/legal/terms-of-service-step-1/:locale',
        component: <Route path="/legal/terms-of-service-step-1/:locale" component={TermsOfServiceLegalPage} />,
      },
      {
        path: '/legal/terms-of-service-step-2/:locale',
        component: <Route path="/legal/terms-of-service-step-2/:locale" component={TermsOfServiceLegalPage} />,
      },
      {
        path: '/legal/terms-of-service-step-3/:locale',
        component: <Route path="/legal/terms-of-service-step-3/:locale" component={TermsOfServiceLegalPage} />,
      },

      { path: '/store/orders/:id', component: <SignInLayoutRoute path="/store/orders/:id" component={Store} /> },

      { path: '/alarms', component: <SignInLayoutRoute path="/alarms" component={Alarms} /> },
      { path: '/add-device', component: <SignInLayoutRoute path="/add-device" component={AddDevice} /> },
      {
        path: '/update-firmware',
        component: <SignInLayoutRoute path="/update-firmware" component={UpdateDeviceFirmware} />,
      },
      { path: '/faq', component: <SignInLayoutRoute path="/faq" component={FaqPage} /> },
      { path: '/about', component: <SignInLayoutRoute path="/about" component={AboutPage} /> },
      {
        path: '/works-with',
        component: <SignInLayoutRoute isHeadingVisible={false} path="/works-with" component={WorksWith} />,
      },
      { path: '/cookies', component: <SignInLayoutRoute path="/cookies" component={CookiesPage} /> },
      {
        path: '/terms-of-service',
        component: <SignInLayoutRoute path="/terms-of-service" component={TermsOfServicePage} />,
      },
      {
        path: '/privacy-policy',
        component: <SignInLayoutRoute path="/privacy-policy" component={PrivacyPolicyPage} />,
      },
      { path: '/contact', component: <SignInLayoutRoute path="/contact" component={ContactPage} /> },
      {
        path: '/product-registration',
        component: <SignInLayoutRoute path="/product-registration" component={ProductRegistration} />,
      },
      {
        path: '/product-registration-info/:sn',
        component: <SignInLayoutRoute path="/product-registration-info/:sn" component={ProductRegistrationInfo} />,
      },
      { path: '/profile-settings', component: <SignInLayoutRoute path="/profile-settings" component={ProfilePage} /> },
      { path: '/invitation/:id', component: <SignInLayoutRoute path="/invitation/:id" component={Invitation} /> },
      {
        path: '/delete/confirmation',
        component: <SignOutLayoutRoute path="/delete/confirmation" component={DeleteConfirmation} />,
      },
      {
        path: '/confirm-email-update',
        component: <SignInLayoutRoute path="/confirm-email-update" component={ConfirmEmailUpdate} />,
      },
      { path: '/password-reset', component: <SignInLayoutRoute path="/password-reset" component={NewPassword} /> },
      { path: '/', component: <SignInLayoutRoute path="/" exact component={Home} /> },
    ],
    premiumRoutes: [
      {
        path: '/device-scheduling',
        component: <SignInLayoutRoute path="/device-scheduling" component={DeviceScheduling} />,
      },
    ],
  },
};

const Router = ({
  match,
  location,
  userLoggedIn,
  allowRedirect,
  systems,
  systemStatus,
  isManageEnabled,
  selectedSystem,
  isDashboardFree,
}) => {
  const enableSystemDependentRoutes = systems && systems.length > 0;

  const systemDependentRoutes = enableSystemDependentRoutes
    ? routesDefinition.restrictedRoutes.systemDependentRoutes
    : [];

  const isDeviceOnline = systemStatus.currentStatus === 'online';

  const premiumRoutes =
    isDeviceOnline && isManageEnabled
      ? routesDefinition.restrictedRoutes.premiumRoutes
      : isDeviceOnline && isDashboardFree
      ? routesDefinition.restrictedRoutes.systemDependentRoutes // Enable user to go to device scheduling page if isDashboardFree is true
      : [];

  // const premiumRoutes = isDeviceOnline && isManageEnabled ? routesDefinition.restrictedRoutes.premiumRoutes : [];

  const routes = userLoggedIn
    ? [...routesDefinition.restrictedRoutes.systemIndependentRoutes, ...systemDependentRoutes, ...premiumRoutes]
    : routesDefinition.anonymousRoutes;

  const redirectTo = !userLoggedIn && !match.isExact && allowRedirect ? '?redirectTo=' + location.pathname : '';

  const { brandId, devices } = selectedSystem || {};
  const firmwareId = selectDevicesFirmwareId(devices);
  const shouldRedirectForPremium = isSystemEligibleForHidingPremium(brandId, firmwareId);
  const shouldRedirectForHistory = isSystemEligibleForHidingHistory(brandId, firmwareId);
  const shouldRedirectFromStore = isSystemHidingStoreMenu(brandId);

  if (
    (shouldRedirectForPremium || shouldRedirectFromStore) &&
    STORE_ROUTES.some(path => location.pathname.includes(path))
  ) {
    return <Redirect to="/" />;
  }
  if (shouldRedirectForHistory && location.pathname === '/history') {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      {routes.map((r, i) => ({ ...r.component, key: i }))}
      <Redirect to={userLoggedIn ? '/' : '/login' + redirectTo} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Router.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  premiumFeatures: PropTypes.object,
  systemStatus: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
  allowRedirect: PropTypes.bool.isRequired,
  isManageEnabled: PropTypes.bool,
  systems: PropTypes.array,
  selectedSystem: PropTypes.object,
  isDashboardFree: PropTypes.bool,
};

const withConnect = connect(({ app, features: { premiumFeatures, isDashboardFree }, ...state }) => ({
  ...app,
  isManageEnabled: premiumFeatures && premiumFeatures.manage,
  systemStatus: state.systemStatus,
  isDashboardFree,
}));

export default compose(withRouter, withConnect)(Router);
